import ApiClient from 'data/api/api_client'
import { LoadingIcon } from 'components/icons'
import { useScreenWidth } from 'data/hooks/useScreenWidth'
import { unescapeHtml } from 'data/helpers/html'
import { useQuery } from 'react-query'
import queryCache from 'data/queryCache'
import classNames from 'classnames'
import { useSearchParams } from 'data/hooks/useSearchParams'
import Link from 'components/Link'
import FixedNavbar from 'components/home/navbar/FixedNavbar'

const MagazineNavbar = () => {
  const { isMobile } = useScreenWidth(1024)
  const { params } = useSearchParams()

  const { data: categories, isLoading } = useQuery(
    queryCache.magazineCategories,
    () => ApiClient.magazine.getCategories(),
  )

  return (
    <FixedNavbar>
      <div className="flex items-center justify-center w-full gap-50">
        {isLoading
          ? <LoadingIcon />
          : <>
            {
              categories && categories.map((category, key) => (
                <Link
                  key={key}
                  href={{
                    pathname: '/magazine/category',
                    query: {
                      categories: category.id,
                      country: params.get('country'),
                      region: params.get('region'),
                      subregion: params.get('subregion'),
                      search: params.get('search'),
                    },
                  }}
                  className={classNames(
                    'uppercase text-14 text-grey-800 tracking-[1.5px] leading-title font-bold',
                    {
                      'py-5': isMobile,
                      'py-10': !isMobile,
                      'border-b-2 border-primary': params.get('categories')?.includes(category?.id?.toString()),
                      'border-transparent': !params.get('categories')?.includes(category?.id?.toString()),
                    },
                  )}
                >
                  {unescapeHtml(category.name)}
                </Link>
              ))
            }
          </>
        }
      </div>
    </FixedNavbar>
  )
}

export default MagazineNavbar
