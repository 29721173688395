import Input from 'components/form/Home/Input'
import Button from 'components/home/common/Button'
import { BellIcon, MapPinIcon, SearchAltIcon, SearchPinIcon } from 'components/icons'
import { MobileScreenWidths, useScreenWidth } from 'data/hooks/useScreenWidth'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { MagazineSearchFormFields } from 'pages/magazine'
import DestinationSelectionPopover from 'components/home/navbar/popovers/specific/DestinationSelectionPopover'
import MagazineCategorySelectionPopover from 'components/home/navbar/popovers/specific/MagazineCategorySelectionPopover'
import MobileDestinationsModal from 'components/modals/home/destinations/MobileDestinationsModal'
import { getSearchURLQueryParameters, joinBy } from '@ama-selections/ui'
import { useSearchParams } from 'data/hooks/useSearchParams'
import { getNumericArrayQueryParameter } from '@ama-selections/ui/src/helpers/destination'

const MagazineSearchBar = () => {
  const { watch, setValue, getValues, reset } = useFormContext<MagazineSearchFormFields>()
  const { params } = useSearchParams()
  const { isMobile } = useScreenWidth(MobileScreenWidths.LG)

  const [isDestinationOpen, setIsDestinationOpen] = useState(false)

  useEffect(() => {
    reset({
      destination: getValues('destination') ?? {
        country: {
          value: params.get('country') ?? undefined,
          slug: params.get('country') ?? undefined,
        },
        region: {
          value: params.get('region') ?? undefined,
          slug: params.get('region') ?? undefined,
        },
        subregion: {
          value: params.get('subregion') ?? undefined,
          slug: params.get('subregion') ?? undefined,
        },
      },
      categories: getValues('categories') ?? getNumericArrayQueryParameter(params.get('categories')),
      destination_search: getValues('destination_search') ?? joinBy([
        params.get('country'),
        params.get('region'),
        params.get('subregion'),
      ]),
      search: getValues('search') ?? params.get('search') ?? undefined,
    })
  }, [params])

  return isMobile
    ? (
      <>
        <div className="relative w-full bg-white rounded-20 px-25">
          <form>
            <div className="flex flex-col gap-8">
              <Input
                variant="searchbar"
                prefix={<MapPinIcon />}
                placeholder="Find your destination"
                value={watch('destination_search')}
                readOnly
                onClick={() => setIsDestinationOpen(true)}
              />

              <Input
                variant="searchbar"
                prefix={<BellIcon />}
                placeholder="What are you looking for?"
                onChange={(e) => setValue('search', e.target.value)}
                value={watch('search')}
              />

              <MagazineCategorySelectionPopover
                className={{
                  button: 'w-full',
                  panel: 'min-w-full !top-[59px]',
                }}
              />

              <Button
                variant="primary"
                href={{
                  pathname: '/magazine/category',
                  query: getSearchURLQueryParameters({
                    country: watch('destination')?.country.value,
                    region: watch('destination')?.region?.value,
                    subregion: watch('destination')?.subregion?.value,
                    categories: watch('categories'),
                    search: watch('search'),
                  }),
                }}
              >
                Search
              </Button>

            </div>
          </form>
        </div>

        <MobileDestinationsModal
          isOpen={isDestinationOpen}
          onClose={() => setIsDestinationOpen(false)}
          redirect={false}
        />
      </>
    )
    : (
      <div className="relative shadow bg-white flex items-center max-w-[854px] w-full mx-auto search-corners">
        <DestinationSelectionPopover
          className={{
            panel: '!top-[calc(100%+2px)] !-ml-20',
          }}
        />

        <div className="h-[48px] border-r-[1px] border-grey border-opacity-30" />

        {/* Search Input */}
        <div
          className="flex items-center gap-15 w-full max-w-[360px] h-[80px] px-30"
        >
          <SearchPinIcon className="flex-shrink-0 text-primary" />
          <input
            type="text"
            placeholder="What are you looking for?"
            className="w-full h-[70px] focus:ring-0 focus:outline-none border-none placeholder-grey-500 text-grey-800"
            value={watch('search')}
            onChange={(e) => setValue('search', e.target.value)}
          />
        </div>

        <div className="h-[48px] border-r-[1px] border-grey border-opacity-30" />

        {/* Filters dropdown */}
        <MagazineCategorySelectionPopover
          className={{
            panel: '!top-[52px]',
          }}
        />

        <div className="flex items-center">
          <Button
            style="pill"
            className={{
              button: '!py-[7px] !px-[21px]',
            }}
            href={{
              pathname: '/magazine/category',
              query: getSearchURLQueryParameters({
                country: watch('destination')?.country.value,
                region: watch('destination')?.region?.value,
                subregion: watch('destination')?.subregion?.value,
                categories: watch('categories'),
                search: watch('search'),
              }),
            }}
          >
            <SearchAltIcon className="text-18" />
          </Button>
        </div>
      </div>
    )
}

export default MagazineSearchBar
