import { useFormContext } from 'react-hook-form'
import NavbarPopover from 'components/home/navbar/popovers/components/NavbarPopover'
import classNames from 'classnames'
import NavbarButton from 'components/home/navbar/common/NavbarButton'
import { FilterSearchBarIcon } from 'components/icons'
import { MobileScreenWidths, useScreenWidth } from 'data/hooks/useScreenWidth'
import Input from 'components/form/Home/Input'
import { MagazineSearchFormFields } from 'pages/magazine'
import he from 'he'
import ControlledPillSwiper from 'components/form/Home/ControlledPillSwiper'
import queryCache from 'data/queryCache'
import ApiClient from 'data/api/api_client'
import { useQuery } from 'react-query'
import { useMemo } from 'react'
import { RestArticleTaxonomy } from 'data/types/article'

interface MagazineCategorySelectionPopoverProps {
  className?: {
    container?: string
    button?: string
    panel?: string
  }
}

const MagazineCategorySelectionPopover = ({
  className,
}: MagazineCategorySelectionPopoverProps) => {
  const { data } = useQuery(
    queryCache.magazineCategories,
    () => ApiClient.magazine.getCategories(),
  )

  const categories: RestArticleTaxonomy[] | undefined = useMemo(
    () => data,
    [data],
  )

  const { watch, control } = useFormContext<MagazineSearchFormFields>()
  const { isMobile } = useScreenWidth(MobileScreenWidths.LG)

  return (
    <NavbarPopover
      trigger={
        isMobile
          ? (
            <Input
              variant="searchbar"
              placeholder="Filters"
              value={watch('categories')?.length > 0
                ? he.decode(categories?.find((category) => category.id === watch('categories')[0])?.name ?? '')
                : ''
              }
              prefix={<FilterSearchBarIcon />}
              readOnly
            />
          )
          : (
            <NavbarButton
              prefix={<FilterSearchBarIcon />}
              active={watch('categories')?.length > 0}
              className={{ container: 'min-w-[176px]' }}
            >
              { watch('categories')?.length > 0
                ? he.decode(categories?.find((category) => category.id === watch('categories')[0])?.name ?? '')
                : 'Filters'
              }
            </NavbarButton>
          )}
      variant="absolute"
      className={{
        panel: classNames('max-w-sm min-w-[345px] flex flex-col top-[52px] items-center', className?.panel),
        button: className?.button,
      }}
    >
      <ControlledPillSwiper
        multiSelect={false}
        control={control}
        title="Categories"
        className={{
          container: 'w-full',
          title: 'px-24',
        }}
        data={(categories ?? []).map((category) => ({
          id: category.id.toString(),
          value: he.decode(category.name),
        }))}
        name="categories"
      />
    </NavbarPopover>
  )
}

export default MagazineCategorySelectionPopover
