import React from 'react'
import placeholderImage from 'assets/images/placeholder.png'
import BannerCardSection from 'components/sections/BannerCardSection'
import he from 'he'
import { ArrowRightIcon } from 'components/icons'
import { RestArticle } from 'data/types/article'

const FeaturedArticle = ({
  article,
}: { article: RestArticle }) => {
  return article && (
    <BannerCardSection
      className={{
        container: '!mb-0 !my-50 lg:!my-125',
        image: '!h-[308px] lg:!h-[900px]',
        content: '!block text-center sm:text-left sm:!text-14 !text-12',
      }}
      heading={article.title && he.decode(article.title)}
      subtitle="Featured Article"
      bannerImage={article.thumbnail?.src ?? placeholderImage}
      altText={article.thumbnail?.alt_text ?? article.title}
      buttonHref={`/magazine/${article.slug}`}
      buttonText="READ MORE"
      buttonIcon={<ArrowRightIcon />}
      content={article.excerpt && he.decode(article.excerpt)}
    />
  )
}

export default FeaturedArticle
