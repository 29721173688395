import React from 'react'
import MagazineCarouselSection from 'components/sections/magazine/MagazineCarouselSection'
import { RestArticlePage } from 'data/types/article'
import { useScreenWidth } from 'data/hooks/useScreenWidth'

interface TopPicksProps {
  articles?: RestArticlePage
}

const TopPicks = ({ articles }: TopPicksProps) => {
  const { isMobile } = useScreenWidth(1024)

  return (
    <MagazineCarouselSection
      className={{
        container: 'md:mb-[180px] md:mt-[77px] mb-50 whitespace-break-spaces',
      }}
      articles={articles}
      subheading="TOP PICKS"
      heading={isMobile ? 'Discover our top pick\nin select destinations' : 'Discover our\ntop picks in select\ndestinations'}
      description="Get inspired by our carefully curated “best of” recommendations. Whether you are looking for the best beaches, restaurants or events, these are our top picks of things to do and places to see that you shouldn't miss."
      button={{
        href: '/magazine/category',
        text: 'VIEW ALL',
      }}
    />
  )
}

export default TopPicks
